<template>
  <div class="event-details" v-if="loaded">
    <div class="column">
      <div class="detail-card">
        <h4>Tournament Director</h4>
        <p>Name: {{ emptyThenDash(eventData.tournamentDirectorName) }}</p>
        <p>Phone: {{ emptyThenDash(eventData.phone) }}</p>
        <p>Email: {{ emptyThenDash(eventData.email) }}</p>
      </div>
      <div class="detail-card">
        <h4>Assistant Tournament Director</h4>
        <p>
          Name: {{ emptyThenDash(eventData.assistantTournamentDirectorName) }}
        </p>
        <p>
          Email: {{ emptyThenDash(eventData.assistantTournamentDirectorEmail) }}
        </p>
        <p>
          Phone: {{ emptyThenDash(eventData.assistantTournamentDirectorPhone) }}
        </p>
      </div>
      <div class="detail-card">
        <h4>Event website</h4>
        <p v-if="eventData.website">
          <a :href="eventData.website" target="_blank">{{
            eventData.website
          }}</a>
        </p>
        <p v-else>-</p>
      </div>
      <div class="detail-card" v-if="eventData.pdgaEventId">
        <h4>PDGA event page</h4>
        <p>
          <a
            :href="'http://www.pdga.com/tour/event/' + eventData.pdgaEventId"
            target="_blank"
            >http://www.pdga.com/tour/event/{{ eventData.pdgaEventId }}</a
          >
        </p>
      </div>
    </div>
    <div class="column">
      <div class="detail-card">
        <h4 class="mb-0">
          Divisions
          <template v-if="eventData.registrationStages.length > 0">
            <span @click="showStages = false" v-if="showStages"
              >Hide stages</span
            >
            <span @click="showStages = true" v-else>Show stages</span>
          </template>
        </h4>
        <template v-for="division in eventData.tour.divisions">
          <h5>
            {{ division.name
            }}<span v-if="division.registrationFee"
              >{{ division.registrationFee.value }}
              {{ division.registrationFee.currency }}</span
            >
            <span v-else>0 SEK</span>
          </h5>
          <div class="reserved" v-if="division.reservedSpots">
            <p>Reserved spots:</p>
            <p class="spots">{{ division.reservedSpots }}</p>
          </div>
          <div class="max-spots" v-if="division.maxSpots">
            <p>Max spots:</p>
            <p class="spots">{{ division.maxSpots }}</p>
          </div>

          <slide-up-down
            class="w-100"
            :key="division.id"
            :active="showStages"
            :duration="300"
          >
            <div
              class="stage"
              v-for="(stage, stageIndex) in stagesInDivision(division)"
              :key="stage.id"
            >
              <p>
                <strong>Stage {{ stageIndex + 1 }}</strong>
              </p>
              <p>{{ stageDate(stage) }}</p>
              <template v-for="criteria in stage.criterias">
                <p v-if="criteria.type == 'OPEN_FOR_ALL'">
                  Anyone can register
                </p>
                <p v-if="criteria.type == 'PDGA_NUMBER'">Only PDGA members</p>
                <p v-if="criteria.type == 'MAX_PDGA_RATING'">
                  Max {{ criteria.value }} rated players
                </p>
                <p v-if="criteria.type == 'MIN_PDGA_RATING'">
                  Min {{ criteria.value }} rated players
                </p>
              </template>
            </div>
          </slide-up-down>
        </template>
      </div>
      <div class="detail-card" v-if="eventData.tour.paymentType == 'MANAGED'">
        <h4>Payment</h4>
        <p>
          Direct payments are enabled for this event. Pay with bank/credit card
          upon registration.
        </p>
      </div>
    </div>
    <div class="column">
      <div class="detail-card" :class="{ single: !eventData.checkInStartsAt }">
        <template v-if="eventData.checkInStartsAt">
          <h4>
            <On />Check in enabled
            <a href="https://support.tjing.se/manager/#check-in" target="_blank"
              >Learn more</a
            >
          </h4>
          <p>
            Opens
            <strong>{{
              checkInDateFormatter(eventData.checkInStartsAt)
            }}</strong>
          </p>
          <p>
            Closes
            <strong>{{ checkInDateFormatter(eventData.checkInEndsAt) }}</strong>
          </p>
        </template>
        <h4 v-else>
          <Off />Check in disabled
          <a href="https://support.tjing.se/manager/#check-in" target="_blank"
            >Learn more</a
          >
        </h4>
      </div>
      <div class="detail-card single">
        <h4 v-if="eventData.isVerifyMode">
          <On />Verifier enabled
          <a href="https://support.tjing.se/verifier/" target="_blank"
            >Learn more</a
          >
        </h4>
        <h4 v-else>
          <Off />Verifier disabled
          <a href="https://support.tjing.se/verifier/" target="_blank"
            >Learn more</a
          >
        </h4>
      </div>
      <div class="detail-card single">
        <h4 v-if="eventData.isResultSigningEnabled">
          <On />Signature enabled
          <a href="https://support.tjing.se/signature/" target="_blank"
            >Learn more</a
          >
        </h4>
        <h4 v-else>
          <Off />Signature disabled
          <a href="https://support.tjing.se/signature/" target="_blank"
            >Learn more</a
          >
        </h4>
      </div>
      <div class="detail-card">
        <h4>Additional details</h4>
        <vue-markdown
          v-if="eventData.description"
          :source="eventData.description"
        ></vue-markdown>
        <p v-else>-</p>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from "lodash";
import moment from "moment";
import On from "@/assets/icons/On";
import Off from "@/assets/icons/Off";
import VueMarkdown from "vue-markdown";
import SlideUpDown from "vue-slide-up-down";
export default {
  components: {
    On,
    VueMarkdown,
    Off,
    SlideUpDown,
  },
  name: "PublicEventDetails",
  data() {
    return {
      loaded: false,
      showStages: false,
      eventData: null,
    };
  },

  methods: {
    emptyThenDash(value) {
      if (!value) return "-";
      return value;
    },
    checkInDateFormatter(date) {
      return moment(date).format("D MMM [at] HH:mm");
    },
    stageDate(stage) {
      return moment(stage.date).format("ddd D MMM [at] HH:mm");
    },
    stagesInDivision(division) {
      let stagesInDivision = this.eventData.registrationStages.filter(
        (stage) => stage.division.id == division.id
      );

      stagesInDivision = orderBy(stagesInDivision, "date", "asc");
      return stagesInDivision;
    },
    async loadData() {
      let eventData = await this.$axios({
        data: {
          query: `
          {
            event(eventId: "${this.$router.currentRoute.params.eventId}") {
              tour{
                paymentType
                divisions{
                  id
                  name
                  reservedSpots
                  maxSpots
                  registrationFee{
                    value
                    currency
                  }
                }
              }
              id
              tournamentDirectorName
              email
              phone
              assistantTournamentDirectorName
              assistantTournamentDirectorEmail
              assistantTournamentDirectorPhone
              website
              pdgaEventId
              checkInStartsAt
              checkInEndsAt
              isVerifyMode
              isResultSigningEnabled
              description
              city{
                id
                country
              }
              registrationStages{
                id
                date
                criterias{
                  id
                  type
                  value
                }
                division{
                  id
                }
              }
            }
          }
          `,
        },
      });

      this.eventData = eventData.data.data.event;
      this.loaded = true;
    },
  },
  beforeMount() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.event-details {
  padding: 0 12px;
}

.detail-card {
  border-radius: 10px;
  border: 1px solid $sleet;
  padding: 18px 20px 22px 20px;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;

  .reserved,
  .max-spots {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .spots {
      text-align: right;
    }
  }
  .reserved {
    padding-top: 8px;
  }

  div,
  p {
    word-break: break-word;
  }

  &.single {
    h4 {
      margin-bottom: 0;
    }
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    font-size: 16px;
    width: 100%;

    strong {
      @include Gilroy-Bold;
    }
    a {
      color: $ocean;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  h4 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    margin-bottom: 12px;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    a {
      font-size: 12px;
      color: $ocean;
      @include Gilroy-Regular;
      margin-left: auto;
    }
    span {
      font-size: 12px;
      color: $ocean;
      @include Gilroy-Regular;
      margin-left: auto;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  h5 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    padding-top: 18px;
    margin-top: 18px;
    margin-bottom: 0;
    border-top: 1px solid $sleet;

    &:first-of-type {
      border-top: none;
      padding-top: 0px;
      margin-top: 14px;
    }
  }

  .stage {
    padding: 14px 0;
    border-bottom: 1px solid $sleet;
    width: 100%;
    p {
      font-size: 14px;
      margin-top: 4px;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .column {
    width: 31%;
  }
  .event-details {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
</style>
