<template>
  <svg
    :class="color"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path class="a" d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z" />
    <path class="b" d="M0,10H6V0" transform="translate(13.071 4) rotate(45)" />
  </svg>
</template>

<script>
export default {
  name: "On",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
.ocean {
  .a {
    fill: $ocean;
  }
  .b {
    fill: none;
    stroke: #fff;
    stroke-width: 2px;
  }
}
.midnight {
  .a {
    fill: $midnight;
  }
  .b {
    fill: none;
    stroke: #fff;
    stroke-width: 2px;
  }
}
.grey {
  .a {
    fill: #7d8296;
  }
  .b {
    fill: none;
    stroke: #fff;
    stroke-width: 2px;
  }
}
.a {
  fill: #00d098;
}
.b {
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
}
</style>